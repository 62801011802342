import * as React from "react"
import { theme } from "../../theme"

interface IconProps {
  color?: string
}

const SearchIcon: React.FunctionComponent<IconProps> = ({ color = theme.colors.greyDark }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="search-icon"
    >
      <path
        id="Vector"
        d="M1.4 18L7.7 11.7C8.2 12.1 8.775 12.4167 9.425 12.65C10.075 12.8833 10.7667 13 11.5 13C13.3167 13 14.854 12.3707 16.112 11.112C17.37 9.85333 17.9993 8.316 18 6.5C18 4.68333 17.3707 3.146 16.112 1.888C14.8533 0.63 13.316 0.000666667 11.5 0C9.68333 0 8.14567 0.629333 6.887 1.888C5.62833 3.14667 4.99933 4.684 5 6.5C5 7.23333 5.11667 7.925 5.35 8.575C5.58333 9.225 5.9 9.8 6.3 10.3L0 16.6L1.4 18ZM11.5 11C10.25 11 9.18733 10.5627 8.312 9.688C7.43667 8.81333 6.99933 7.75067 7 6.5C7 5.25 7.43733 4.18767 8.312 3.313C9.18667 2.43833 10.2493 2.00067 11.5 2C12.75 2 13.8123 2.43767 14.687 3.313C15.5617 4.18833 15.9993 5.25067 16 6.5C16 7.75 15.5623 8.81267 14.687 9.688C13.8117 10.5633 12.7493 11.0007 11.5 11Z"
        fill={color}
      />
    </svg>
  )
}

export default SearchIcon
