import React, { createContext, useState, useContext, useEffect, useRef } from "react"
import { Ws } from "../websocket/websocket"
import * as protoMetrics from "edge-proto/dist/edge/v1/metrics"
import * as protoLogs from "edge-proto/dist/edge/v1/logs"
import * as protoSystemMessage from "edge-proto/dist/edge/v1/system_message"
import * as protoConfig from "edge-proto/dist/edge/v1/config"
import * as protoDeviceMetadata from "edge-proto/dist/edge/v1/device_metadata"
import * as protoSoftwareUpdate from "edge-proto/dist/edge/v1/software_update"
import * as protoFactoryReset from "edge-proto/dist/edge/v1/factory_reset"
import * as protoFileUpload from "edge-proto/dist/edge/v1/file_upload"
import { Metrics, newMetrics } from "./Metrics"
import { FactoryResetError, MetricValueError, ProtoMissingFieldError, SoftwareUpdateError } from "./Errors"
import { DeviceMetadata, parseMetadata } from "./DeviceMetadata"
import {
  EDGE_WS_METRICS,
  EDGE_WS_EVENTS,
  EDGE_HTTP_METADATA,
  EDGE_WS_CONFIG,
  EDGE_WS_CONFIG_UPDATE,
  EDGE_HTTP_FILE_UPLOAD,
  EDGE_WS_SOFTWARE_UPDATE,
  EDGE_WS_FACTORY_RESET,
  EDGE_WS_LOGS,
  EDGE_WS_SYSTEM_MESSAGES,
  EDGE_HTTP_FILE_DOWNLOAD_LOGS,
  EDGE_HTTP_FILE_DOWNLOAD_DIAGNOSTICS,
} from "./endpoints"
import { useLogin } from "../auth/LoginProvider"
import { clearUser } from "../redux/slices/userSlice"
import { useDispatch } from "react-redux"
import { StatusCodes } from "http-status-codes"
import { Config, NamespaceId, newConfig } from "./Config"
import { UIConfig, newUIConfig } from "./UIConfig"
import {
  DeviceConfigMotion,
  DeviceMetricsMotion,
  newDeviceConfigMotion,
  newDeviceMetricsMotion,
  logLevelMapper,
} from "./device_gen/motion"
import { clientSoftwareUpdateMessage, newSoftwareUpdateState, SoftwareUpdateState } from "./SoftwareUpdate"
import { parseFileUploadMessage } from "./FileUpload"
import {
  LogLevelMapper,
  LogsConfig,
  parseLogEntryMessage,
  applySearchFilter,
  requiresNewWsConnection,
  isNullFilter,
} from "./Logs"
import { clientFactoryResetMessage, FactoryResetState, newFactoryResetState } from "./FactoryReset"
import { LogEntries, LogEntry } from "./Logs"
import { parseSystemMessages, SystemMessages } from "./SystemMessages"
import utc from "dayjs/plugin/utc"
import dayjs from "dayjs"
dayjs.extend(utc)

const SLEEP_POST_REBOOT = 3000
const INIT_LOG_COUNT = 100
const PAGINATE_LOG_COUNT = 300

// In the WebGUI application, we store the widget name containing the
// the config value for each changed value. This way we can display
// the widget origins of the changed config values in the banner used
// to apply changed values. This is hence the type parameter used in
// Config, UIConfig and DeviceConfigX. See the Config interface
// documentation for more information.
export type WebguiConfigExtraData = string

// We currently only have a single device that exposes
// an edge API. In the future we might have more, so we
// expose this behind a sum type.
//
// export type DeviceConfig = DeviceConfigMotion<string> | FutureDevice<string>
export type DeviceConfig = DeviceConfigMotion<WebguiConfigExtraData>
// Same for Metrics
export type DeviceMetrics = DeviceMetricsMotion

interface EdgeProviderType {
  metadata?: DeviceMetadata
  metrics: DeviceMetrics
  uiConfig: UIConfig<WebguiConfigExtraData>
  config: DeviceConfig
  events: { [key: string]: number }
  isConnectedToDevice: boolean
  updateSoftware: (file: File) => void
  softwareUpdateState: SoftwareUpdateState | undefined
  factoryReset: () => void
  factoryResetState: FactoryResetState | undefined
  logs: LogEntries
  logsConfig?: LogsConfig
  setLogsConfig: (logsConfig?: LogsConfig) => void
  logsPaginate: () => void
  systemMessages: SystemMessages
  downloadLogs: () => void
  downloadDiagnostics: (name: string, email: string, message: string) => void
}

const EdgeContext = createContext<EdgeProviderType | undefined>(undefined)

function showErrorDialog(errorMsg: string) {
  // TODO: Make proper dialog here, instead of native browser alert.
  alert(errorMsg)
}

const useDeviceMetadata = (
  isLoggedIn: boolean,
  onDeviceMetadata: (deviceMetadata: DeviceMetadata) => void,
): DeviceMetadata | undefined => {
  const dispatch = useDispatch()

  const [metadata, setMetadata] = useState<DeviceMetadata | undefined>(undefined)

  useEffect(() => {
    async function fetchMetadata() {
      if (!isLoggedIn) {
        setMetadata(undefined)
        return
      }
      // TODO: Handle error
      const response = await fetch(EDGE_HTTP_METADATA, { credentials: "include" }).catch((_err) => {
        console.log(_err)
      })
      // TODO: Handle error
      if (response === undefined) {
        return
      }
      if (!response.ok) {
        if (response.status === StatusCodes.UNAUTHORIZED) {
          dispatch(clearUser("api_unauthorized"))
        }
        return
      }
      const bodyBuffer = await response.arrayBuffer()
      const deviceMetadataMessage = protoDeviceMetadata.DeviceMetadataMessage.decode(new Uint8Array(bodyBuffer))
      const deviceMetadata = parseMetadata(deviceMetadataMessage)
      if (deviceMetadata instanceof ProtoMissingFieldError) {
        // TODO: Handle error
        console.log(`Missing field in DeviceMetadataMessage: ${deviceMetadata}`)
        return
      }
      onDeviceMetadata(deviceMetadata)
      setMetadata(deviceMetadata)
    }
    fetchMetadata()
    return
  }, [isLoggedIn])
  return metadata
}

const useMetrics = (isLoggedIn: boolean): [Metrics, boolean, (deviceMetdata: DeviceMetadata) => void] => {
  // TODO: Handle error
  const [metrics, setMetrics] = useState(
    newMetrics((error) => {
      console.log(`Unexpected error on metrics: ${error}`)
    }),
  )
  const [connected, setConnected] = useState(false)

  const wsRef = useRef<Ws | undefined>(undefined)

  const warnedMessages = useRef<Set<string>>(new Set())
  // We don't want to spam alerts on each incomming faulty metric message,
  // so we use this guard here.
  const warnMetric = (message: string) => {
    if (warnedMessages.current.has(message)) {
      return
    }
    warnedMessages.current.add(message)
    alert(`Unexpected issue with incomming metric message: ${message}`)
  }

  useEffect(() => {
    const ws = wsRef.current
    if (ws !== undefined) {
      ws.close()
      wsRef.current = undefined
    }
    if (!isLoggedIn) {
      return
    }
    const onmessage = (_ws: Ws, ev: MessageEvent<ArrayBuffer>): void => {
      setMetrics((prevMetrics) => {
        let metricMessage
        try {
          // Decode issues aren't really a thing for the typescript protobuf generation implementation.
          // For example a bogus protobuf message results in a message with undefined fields. However,
          // the only exception to this seems to be when number types get values that are larger than
          // Number.MAX_SAFE_INTEGER. We don't really use int64, but the timestamp uses an int64 internally.
          // Hence, if a timestamp with a really large value is received, which can happen if edge is buggy,
          // an error is thrown.
          metricMessage = protoMetrics.MetricMessage.decode(new Uint8Array(ev.data))
        } catch (err) {
          if (!(err instanceof Error)) {
            warnMetric(`Unexpected error type in metrics: ${err}`)
          } else {
            warnMetric(err.message)
          }
          return prevMetrics
        }
        const newMetrics = prevMetrics.update(metricMessage)
        if (newMetrics instanceof ProtoMissingFieldError) {
          // TODO: Show error here
          console.log(`Protobuf metric message is missing a field: ${newMetrics.message}`)
          return prevMetrics
        }
        if (newMetrics instanceof MetricValueError) {
          // TODO: Show error here
          console.log(newMetrics.message)
          return prevMetrics
        }
        return newMetrics
      })
    }
    const onopen = (): void => {
      setConnected(true)
    }
    const onclose = (ws: Ws): void => {
      setConnected(false)
      ws.reconnect()
    }
    const onerror = (_: Ws): void => {
      setConnected(false)
    }

    wsRef.current = new Ws(EDGE_WS_METRICS, onmessage, onopen, onclose, onerror)
  }, [isLoggedIn])

  return [
    metrics,
    connected,
    (deviceMetadata) => {
      setMetrics((prevMetrics) => prevMetrics.setMetadata(deviceMetadata))
    },
  ]
}

const useConfig = (
  isLoggedIn: boolean,
): [
  Config<WebguiConfigExtraData>,
  (newConfig: Config<WebguiConfigExtraData>) => void,
  () => void,
  (deviceMetdata: DeviceMetadata) => void,
] => {
  // TODO: Handle error
  const [config, setConfig] = useState(newConfig<WebguiConfigExtraData>())

  const wsRef = useRef<Ws | undefined>(undefined)

  const handleServerMessage = (ev: MessageEvent<ArrayBuffer>): void => {
    const updateConfig = (prevConfig: Config<WebguiConfigExtraData>): Config<WebguiConfigExtraData> => {
      // TODO: Handle decode issues
      const configMessage = protoConfig.ConfigMessage.decode(new Uint8Array(ev.data))
      const newConfig = prevConfig.update(configMessage)
      if (newConfig instanceof ProtoMissingFieldError) {
        // TODO: Show error here
        console.log(`Protobuf config message is missing a field: ${newConfig.message}`)
        return prevConfig
      }
      return newConfig
    }
    setConfig(updateConfig)
  }

  useEffect(() => {
    const ws = wsRef.current
    if (ws !== undefined) {
      ws.close()
      wsRef.current = undefined
    }
    if (!isLoggedIn) {
      return
    }

    const onmessage = (_ws: Ws, ev: MessageEvent<ArrayBuffer>): void => {
      handleServerMessage(ev)
    }
    const onopen = (): void => {
      return
    }
    const onclose = (): void => {
      setConfig((config) => config.endRead())
      return
    }
    const onerror = (_: Ws): void => {
      return
    }

    wsRef.current = new Ws(EDGE_WS_CONFIG, onmessage, onopen, onclose, onerror)
  }, [isLoggedIn])

  const pushToDevice = (namespace?: NamespaceId) => {
    const onmessage = (_ws: Ws, ev: MessageEvent<ArrayBuffer>): void => {
      handleServerMessage(ev)
    }
    const onopen = (ws: Ws): void => {
      const protoMessage = config.pushChangedConfigProtoMessage(namespace)
      setConfig(config.beginPush(namespace))
      ws.send(protoConfig.ClientConfigMessage.encode(protoMessage).finish())
    }
    const onclose = (): void => {
      setConfig((config) => config.endPush(namespace))
      return
    }
    const onerror = (_: Ws): void => {
      // TODO: Handle error
      console.log(`Unexpected websocket error when trying to write config to device server`)
    }

    new Ws(EDGE_WS_CONFIG_UPDATE, onmessage, onopen, onclose, onerror)
    return
  }

  return [
    config,
    setConfig,
    pushToDevice,
    (deviceMetadata) => {
      setConfig((prevMetrics) => prevMetrics.setMetadata(deviceMetadata))
    },
  ]
}

const useEvents = (isLoggedIn: boolean): [{ [key: string]: number }, boolean] => {
  const [events, setEvents] = useState({ count: 0 })
  const [connected, setConnected] = useState(false)

  const wsRef = useRef<Ws | undefined>(undefined)

  useEffect(() => {
    const ws = wsRef.current
    if (ws !== undefined) {
      ws.close()
      wsRef.current = undefined
    }
    if (!isLoggedIn) {
      return
    }
    const onmessage = (): void => {
      setEvents((prevEvents) => {
        // TODO: Here we should parse protobuf message and
        // put in this object once we implement protobuf
        // parsing.
        return { count: prevEvents.count + 1 }
      })
    }
    const onopen = (): void => {
      setConnected(true)
    }
    const onclose = (ws: Ws): void => {
      setConnected(false)
      ws.reconnect()
    }
    const onerror = (): void => {
      setConnected(false)
    }

    wsRef.current = new Ws(EDGE_WS_EVENTS, onmessage, onopen, onclose, onerror)
  }, [isLoggedIn])
  return [events, connected]
}

type LogAction =
  | { type: "append"; invocationId: string; logEntry: LogEntry }
  | { type: "prepend"; invocationId: string; logEntry: LogEntry }
  | { type: "reset" }
type LogsState = {
  invocationId: string
  lastCursor?: string
  followCursor?: string
  queryFilter: string
  logActions: LogAction[]
  paginate: boolean
  allowPaginate: boolean
  follow: boolean
}

const useLogs = (
  logLevelMapper: LogLevelMapper,
): [LogEntries, LogsConfig | undefined, (services?: LogsConfig) => void, () => void] => {
  const logsRef = useRef<LogEntry[]>([])
  const wsRef = useRef<Ws | undefined>(undefined)
  const wsFollowRef = useRef<Ws | undefined>(undefined)
  const wsPaginateRef = useRef<Ws | undefined>(undefined)

  const [logsState, setLogsState] = useState<LogsState>({
    invocationId: "",
    lastCursor: undefined,
    followCursor: undefined,
    queryFilter: "",
    logActions: [],
    paginate: false,
    allowPaginate: false,
    follow: false,
  })

  const addAction = (logsState: LogsState, action: LogAction) => {
    return { ...logsState, logActions: [...logsState.logActions, action] }
  }

  const addActionToState = (action: LogAction) => {
    setLogsState((logsState: LogsState) => {
      return addAction(logsState, action)
    })
  }

  // The logsRef can contain a large array. This is the reason why this is a
  // ref and not a react state, since then we have to use the spread pattern
  // e.g. [myNewLog, prevLogs], which isn't performant. To solve this,
  // websockets pushes actions that is consumed by this effect function. This
  // makes sure we never use the spread pattern on any large arrays.
  useEffect(() => {
    const consumeActions = logsState.logActions
    if (consumeActions.length === 0) {
      return
    }
    consumeActions.forEach((logAction: LogAction) => {
      switch (logAction.type) {
        case "reset":
          logsRef.current = []
          break
        case "append":
          if (logsState.invocationId === logAction.invocationId) {
            logsRef.current.unshift(logAction.logEntry)
          }
          break
        case "prepend":
          if (logsState.invocationId === logAction.invocationId) {
            logsRef.current.push(logAction.logEntry)
          }
          break
      }
    })
    setLogsState((logsState: LogsState) => {
      return {
        ...logsState,
        logActions: logsState.logActions.slice(consumeActions.length, logsState.logActions.length),
      }
    })
  }, [logsState.logActions])

  const [logsConfig, setLogsConfig] = useState<LogsConfig | undefined>(undefined)

  const closeWs = (wsRef: React.MutableRefObject<Ws | undefined>) => {
    const ws = wsRef.current
    if (ws !== undefined) {
      ws.close()
      wsRef.current = undefined
    }
  }

  const parseMessage = (ev: MessageEvent<ArrayBuffer>) => {
    const protoLogEntryMessage = protoLogs.LogEntryMessage.decode(new Uint8Array(ev.data))
    const logEntryMessage = parseLogEntryMessage(protoLogEntryMessage, logLevelMapper.toLogLevel)
    if (logEntryMessage instanceof ProtoMissingFieldError) {
      console.log(`Failed to parse log message: ${logEntryMessage}`)
      return
    }
    return logEntryMessage
  }

  const runLogsWebsocket = (
    logsState: LogsState,
    queryParam: string,
    onMessage: (msg: LogEntry) => void,
    onClose: () => void,
  ): Ws => {
    const onmessage = (_ws: Ws, ev: MessageEvent<ArrayBuffer>): void => {
      const msg = parseMessage(ev)
      if (msg === undefined) {
        return
      }
      onMessage(msg)
      return
    }
    const onopen = (): void => {
      return
    }
    const onclose = (): void => {
      onClose()
      return
    }
    const onerror = (): void => {
      return
    }
    return new Ws(`${EDGE_WS_LOGS}?${queryParam}${logsState.queryFilter}`, onmessage, onopen, onclose, onerror)
  }

  // Websocket used for following messages to get live updates
  useEffect(() => {
    closeWs(wsFollowRef)
    if (logsState.followCursor === undefined) {
      return
    }
    if (!logsState.follow) return
    wsFollowRef.current = runLogsWebsocket(
      logsState,
      `follow=true&start_cursor=${logsState.followCursor}`,
      (msg: LogEntry) => {
        // First message is already fetched from first websocket
        if (msg.cursor === logsState.followCursor) {
          return
        }
        addActionToState({ type: "append", logEntry: msg, invocationId: logsState.invocationId })
        return
      },
      () => {
        return
      },
    )
  }, [logsState.invocationId, logsState.followCursor])

  // Websocket used for pagination
  useEffect(() => {
    if (!logsState.paginate || !logsState.allowPaginate) return
    if (logsRef.current.length === 0) return
    const oldestLog = logsRef.current[logsRef.current.length - 1]
    closeWs(wsPaginateRef)
    wsFollowRef.current = runLogsWebsocket(
      logsState,
      `start_cursor=${oldestLog.cursor}&end_count=${PAGINATE_LOG_COUNT}&reverse=true`,
      (msg: LogEntry) => {
        // First message is already fetched from first websocket
        if (msg.cursor === oldestLog.cursor) {
          return
        }
        addActionToState({ type: "prepend", logEntry: msg, invocationId: logsState.invocationId })
        return
      },
      () => {
        setLogsState((logsState: LogsState) => {
          return { ...logsState, paginate: false }
        })
      },
    )
  }, [logsState.invocationId, logsState.followCursor, logsState.paginate])

  const logsPaginate = () => {
    setLogsState((logsState: LogsState) => {
      return { ...logsState, paginate: true }
    })
  }

  const setLogsConfigFunc = (newLogsConfig?: LogsConfig) => {
    setLogsConfig(newLogsConfig)
    if (wsRef.current !== undefined && !requiresNewWsConnection(logsConfig, newLogsConfig)) {
      return
    }

    const invocationId = Math.random()
      .toString(32)
      .substring(2, 2 + 20)

    const logLevelsFilter = newLogsConfig?.logLevelsFilter
    const logLevelsQuery =
      logLevelsFilter !== undefined
        ? `&log_levels=${logLevelsFilter.map(logLevelMapper.fromLogLevel).flat(1).join("|")}`
        : ""
    const logServicesFilter = newLogsConfig?.logServicesFilter
    const logServicesQuery = logServicesFilter !== undefined ? `&services=${logServicesFilter.join("|")}` : ""
    const queryFilter = `${logLevelsQuery}${logServicesQuery}`

    const startTime = newLogsConfig?.logStartTime?.utc().format()
    const endTime = newLogsConfig?.logEndTime?.utc().format()

    let startKv = "start_rel_time=0s"
    if (startTime !== undefined) {
      startKv = `start_time=${encodeURIComponent(startTime)}`
    } else if (endTime !== undefined) {
      startKv = ""
    }

    let endKv = `end_count=${INIT_LOG_COUNT}`
    if (endTime !== undefined) {
      endKv = `end_time=${encodeURIComponent(endTime)}`
    } else if (startTime !== undefined) {
      endKv = ""
    }

    const reverse = startTime === undefined && endTime === undefined

    setLogsState((logsState) => {
      return addAction(
        {
          ...logsState,
          invocationId,
          queryFilter,
          lastCursor: undefined,
          followCursor: undefined,
          allowPaginate: startTime === undefined,
          follow: endTime === undefined,
        },
        { type: "reset" },
      )
    })
    closeWs(wsRef)

    if (newLogsConfig === undefined || isNullFilter(newLogsConfig)) return

    const onmessage = (_ws: Ws, ev: MessageEvent<ArrayBuffer>): void => {
      const msg = parseMessage(ev)
      if (msg === undefined) {
        return
      }
      setLogsState((logsState) => {
        if (logsState.invocationId !== invocationId) {
          return logsState
        }
        const followCursor = reverse ? logsState.followCursor ?? msg.cursor : undefined
        const lastCursor = !reverse ? msg.cursor : undefined
        return addAction(
          { ...logsState, followCursor: followCursor, lastCursor: lastCursor },
          { type: reverse ? "prepend" : "append", logEntry: msg, invocationId: invocationId },
        )
      })
      return
    }
    const onopen = (): void => {
      return
    }
    const onclose = (): void => {
      setLogsState((logsState) => {
        if (logsState.invocationId !== invocationId) {
          return logsState
        }
        if (reverse || endTime !== undefined) {
          return logsState
        }
        return { ...logsState, followCursor: logsState.lastCursor }
      })
      return
    }
    const onerror = (): void => {
      return
    }
    const addOptionalQuery = (queryParam: string) => (queryParam === "" ? "" : `&${queryParam}`)
    wsRef.current = new Ws(
      `${EDGE_WS_LOGS}?reverse=${reverse}${addOptionalQuery(startKv)}${addOptionalQuery(endKv)}${queryFilter}`,
      onmessage,
      onopen,
      onclose,
      onerror,
    )
  }

  const searchFilterMessages =
    logsConfig !== undefined ? applySearchFilter(logsConfig, logsRef.current) : logsRef.current

  return [searchFilterMessages, logsConfig, setLogsConfigFunc, logsPaginate]
}

const useSystemMessages = (isLoggedIn: boolean): SystemMessages => {
  const wsRef = useRef<Ws | undefined>(undefined)
  const warnedMessages = useRef<Set<string>>(new Set())

  const [systemMessages, setSystemMessages] = useState<SystemMessages>([])

  const warnSystemMessage = (message: string) => {
    if (warnedMessages.current.has(message)) {
      return
    }
    warnedMessages.current.add(message)
    alert(`Unexpected issue with incoming system message: ${message}`)
  }

  useEffect(() => {
    const ws = wsRef.current
    if (ws !== undefined) {
      ws.close()
      wsRef.current = undefined
    }
    if (!isLoggedIn) {
      return
    }
    const onmessage = (_ws: Ws, ev: MessageEvent<ArrayBuffer>): void => {
      setSystemMessages((prevSystemMessages: SystemMessages) => {
        try {
          const systemMessage = protoSystemMessage.SystemMessage.decode(new Uint8Array(ev.data))
          const newSystemMessages = parseSystemMessages(systemMessage, prevSystemMessages)
          if (newSystemMessages instanceof ProtoMissingFieldError) {
            // TODO: Handle error
            return prevSystemMessages
          }
          return newSystemMessages
        } catch (err) {
          if (!(err instanceof Error)) {
            warnSystemMessage(`Unexpected error type in system messages: ${err}`)
          } else {
            warnSystemMessage(err.message)
          }
          return prevSystemMessages
        }
      })
    }
    const onopen = (): void => {
      setSystemMessages([])
      return
    }
    const onclose = (ws: Ws): void => {
      ws.reconnect()
    }
    const onerror = (_: Ws): void => {
      return
    }

    wsRef.current = new Ws(EDGE_WS_SYSTEM_MESSAGES, onmessage, onopen, onclose, onerror)
  }, [isLoggedIn])

  return systemMessages
}

const downloadFile = async (endpoint: string, filename: string) => {
  const response = await fetch(endpoint, { credentials: "include" }).catch((err) => {
    showErrorDialog(`Failed to download file, unable to communicate with server: ${err}`)
    return
  })
  if (response === undefined) {
    return
  }
  if (!response.ok) {
    showErrorDialog(`Failed to download file, endpoint responded with a non 200 return code (${response.status})`)
    return
  }

  try {
    const blob = await response.blob()
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  } catch (err) {
    showErrorDialog(`Failed to download file, could not fetch downloaded file from browser: ${err}`)
    return
  }
}

const useDownloadLogs = (): (() => void) => {
  return async () => {
    downloadFile(EDGE_HTTP_FILE_DOWNLOAD_LOGS, "logs_diagnostics_package.tar.gz")
  }
}

const useDownloadDiagnostics = (): ((name: string, email: string, message: string) => void) => {
  return async (name: string, email: string, message: string) => {
    const queryParams = [
      ["name", name],
      ["email", email],
      ["message", message],
    ]
      .map((keyValue) => {
        const [key, value] = keyValue
        return `${key}=${encodeURIComponent(value)}`
      })
      .join("&")
    downloadFile(`${EDGE_HTTP_FILE_DOWNLOAD_DIAGNOSTICS}?${queryParams}`, "diagnostics_package.tar.gz")
  }
}

const useSoftwareUpdate = (): [(file: File) => void, SoftwareUpdateState | undefined] => {
  const dispatch = useDispatch()
  const [updateFile, setUpdateFile] = useState<File | undefined>(undefined)
  const [softwareUpdateState, setSoftwareUpdateState] = useState<SoftwareUpdateState | undefined>(undefined)

  useEffect(() => {
    async function updateSoftware() {
      if (updateFile === undefined) {
        return
      }
      setSoftwareUpdateState(newSoftwareUpdateState("upload_file"))
      const onSoftwareUpdateError = (error: string) => {
        setSoftwareUpdateState(undefined)
        // TODO: Implement proper error dialog
        alert(`Unexpected issue occurred when applying the software update: ${error}`)
      }
      const formData = new FormData()
      formData.append("software_update_bundle", updateFile)
      const response = await fetch(EDGE_HTTP_FILE_UPLOAD, {
        credentials: "include",
        method: "POST",
        body: formData,
      }).catch((error) => {
        onSoftwareUpdateError(`Unable to post request to file_upload endpoint: ${error.message}`)
        return
      })

      if (response === undefined) {
        return
      }
      if (!response.ok) {
        if (response.status === StatusCodes.UNAUTHORIZED) {
          dispatch(clearUser("api_unauthorized"))
        }
        onSoftwareUpdateError(`Unexpected response from file_upload endpoint, status code: ${response.status}`)
        return
      }

      const bodyBuffer = await response.arrayBuffer()
      const fileUploadResultMsg = protoFileUpload.FileUploadResultMessage.decode(new Uint8Array(bodyBuffer))
      const fileReferences = parseFileUploadMessage(fileUploadResultMsg)
      if (fileReferences instanceof ProtoMissingFieldError) {
        onSoftwareUpdateError(`Failed to parse file reference from file_upload endpoint: ${fileReferences.message}`)
        return
      }
      if (fileReferences.length !== 1) {
        onSoftwareUpdateError(
          `Unexpected number of file references from file_upload endpoint, expected exactly 1 but got ${fileReferences.length}`,
        )
        return
      }
      const fileReference = fileReferences[0]
      const softwareUpdateMsg = clientSoftwareUpdateMessage(fileReference)
      const onmessage = (_ws: Ws, ev: MessageEvent<ArrayBuffer>) => {
        const updateMsg = protoSoftwareUpdate.SoftwareUpdateMessage.decode(new Uint8Array(ev.data))
        setSoftwareUpdateState((state) => {
          if (state === undefined) {
            return state
          }
          const newState = state.update(updateMsg)
          if (newState instanceof SoftwareUpdateError) {
            onSoftwareUpdateError(newState.message)
            return state
          }
          if (newState instanceof ProtoMissingFieldError) {
            onSoftwareUpdateError(`Failed to parse software update message from server: ${newState.message}`)
            return state
          }
          return newState
        })
      }
      const onopen = (ws: Ws) => {
        ws.send(protoSoftwareUpdate.ClientSoftwareUpdateMessage.encode(softwareUpdateMsg).finish())
        return
      }
      const onclose = () => {
        setSoftwareUpdateState((state) => {
          if (state === undefined) {
            return undefined
          }
          // TODO: We should really get the new version from state here, and
          // wait for the metadata to to have the new version number before
          // doing a reload here in order to make sure the upgrade was successful.
          // But the edge api currently don't expose the version correctly,
          // so we simply wait and then reload the page.
          new Promise((resolve) => setTimeout(resolve, SLEEP_POST_REBOOT)).then(() => window.location.reload())
          return state
        })
      }
      const onerror = () => {
        onSoftwareUpdateError(`Websocket unexpectedly closed with an error`)
        return
      }
      new Ws(EDGE_WS_SOFTWARE_UPDATE, onmessage, onopen, onclose, onerror)
    }
    updateSoftware()
  }, [updateFile])

  const updateSoftware = (file: File) => {
    setUpdateFile(file)
  }

  return [updateSoftware, softwareUpdateState]
}

const useFactoryReset = (logout: () => Promise<void>): [() => void, FactoryResetState | undefined] => {
  const [factoryResetState, setFactoryResetState] = useState<FactoryResetState | undefined>(undefined)

  const factoryReset = () => {
    setFactoryResetState(newFactoryResetState("init"))
    const onFactoryResetError = (error: string) => {
      setFactoryResetState(undefined)
      // TODO: Implement proper error dialog
      alert(`Unexpected issue occurred when applying the factory update: ${error}`)
    }
    const onmessage = (_ws: Ws, ev: MessageEvent<ArrayBuffer>) => {
      const updateMsg = protoFactoryReset.FactoryResetMessage.decode(new Uint8Array(ev.data))
      setFactoryResetState((state) => {
        if (state === undefined) {
          return state
        }
        const newState = state.update(updateMsg)
        if (newState instanceof FactoryResetError) {
          onFactoryResetError(newState.message)
          return state
        }
        if (newState instanceof ProtoMissingFieldError) {
          onFactoryResetError(`Failed to parse factory update message from server: ${newState.message}`)
          return state
        }
        return newState
      })
    }
    const onopen = (ws: Ws) => {
      ws.send(protoFactoryReset.ClientFactoryResetMessage.encode(clientFactoryResetMessage()).finish())
      return
    }
    const onclose = () => {
      setFactoryResetState((state) => {
        if (state === undefined) {
          return undefined
        }
        new Promise((resolve) => setTimeout(resolve, SLEEP_POST_REBOOT)).then(() => logout())
        return state
      })
    }
    const onerror = () => {
      onFactoryResetError(`Websocket unexpectedly closed with an error`)
      return
    }
    new Ws(EDGE_WS_FACTORY_RESET, onmessage, onopen, onclose, onerror)
  }

  return [factoryReset, factoryResetState]
}

export const EdgeProvider = ({ children }: { children: React.ReactNode }) => {
  const { isLoggedIn, logout } = useLogin()
  const [metrics, metricsConnected, setDeviceMetadataMetrics] = useMetrics(isLoggedIn)
  const [config, setConfig, pushToDevice, setDeviceMetadataConfig] = useConfig(isLoggedIn)
  const uiConfig = newUIConfig(config, setConfig, pushToDevice, (error) => {
    // TODO: Add error handling
    console.log(`Unexpected config error: ${error}`)
  })
  // Events are currently not implemented. This can be seen in the developer
  // console.
  const [events, _eventsConnected] = useEvents(isLoggedIn)
  const metadata = useDeviceMetadata(isLoggedIn, (metadata) => {
    setDeviceMetadataMetrics(metadata)
    setDeviceMetadataConfig(metadata)
  })
  const systemMessages = useSystemMessages(isLoggedIn)
  const [updateSoftware, softwareUpdateState] = useSoftwareUpdate()
  const [factoryReset, factoryResetState] = useFactoryReset(logout)
  const logLevelMapper_: LogLevelMapper = logLevelMapper
  const [logs, logsConfig, setLogsConfig, logsPaginate] = useLogs(logLevelMapper_)
  const downloadLogs = useDownloadLogs()
  const downloadDiagnostics = useDownloadDiagnostics()
  // TODO: metricsConnected && eventsConnected
  const isConnectedToDevice = metricsConnected

  return (
    <EdgeContext.Provider
      value={{
        metrics: newDeviceMetricsMotion(metrics),
        uiConfig,
        config: newDeviceConfigMotion(uiConfig),
        events,
        metadata: metadata,
        isConnectedToDevice,
        updateSoftware,
        softwareUpdateState,
        factoryReset,
        factoryResetState,
        logs,
        logsConfig,
        setLogsConfig,
        logsPaginate,
        systemMessages,
        downloadLogs,
        downloadDiagnostics,
      }}
    >
      {children}
    </EdgeContext.Provider>
  )
}

export const useEdgeApi = () => {
  const context = useContext(EdgeContext)
  if (!context) {
    throw new Error("useEdgeApi must be used within a EdgeProvider")
  }
  return context
}
