import * as React from "react"

interface IconPropsState {
  color?: string
}

interface IconProps extends IconPropsState {
  enabled: boolean
}

// eslint-disable-next-line
const CheckboxIconEnabled: React.FunctionComponent<IconPropsState> = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.5" y="1.5" width="21" height="21" rx="1.5" fill="white" stroke={color} strokeWidth="3" />
    <rect x="6.26086" y="6.26086" width="11.4783" height="11.4783" rx="1" fill={color} />
  </svg>
)

// eslint-disable-next-line
const CheckboxIconDisabled: React.FunctionComponent<IconPropsState> = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.5" y="1.5" width="21" height="21" rx="1.5" fill="white" stroke={color} strokeWidth="3" />
  </svg>
)

export const CheckboxIcon: React.FunctionComponent<IconProps> = ({ enabled = false, color = "#616161" }) => {
  return enabled ? <CheckboxIconEnabled color={color} /> : <CheckboxIconDisabled color={color} />
}
