import * as React from "react"
import SettingsOverviewCard from "../../molecules/SettingsOverviewCard"
import SystemMessagesIcon from "../../atoms/icons/SystemMessagesIcon"
import SystemMessageField from "../../molecules/card/SystemMessageField"
import styled from "styled-components"
import LinkIcon from "../../atoms/icons/LinkIcon"
import { HorizontalSpacer } from "../../atoms/spacers"
import PrimaryButton from "../../atoms/buttons/PrimaryButton"
import { SECTION_OVERVIEW_SYSTEM_MESSAGES } from "../../../utils/pages"
import { Link } from "react-router-dom"
import { Col, Row } from "react-grid-system"
import { CheckboxIcon } from "../../atoms/icons/CheckboxIcon"
import ButtonTextSmall from "../../atoms/typography/ButtonTextSmall"
import { useEdgeApi } from "../../../edge/EdgeProvider"
import { SystemMessages, unresolvedSystemMessages, warningAndErrorSystemMessages } from "../../../edge/SystemMessages"

const SYSTEM_MESSAGES_TOOLTIP = `
Relevant system messages concerning operation of the terminal are presented 
for troubleshooting purposes. The following types of messages can be viewed: 
Error, Warning and Info.
`

const ErrorButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  gap: 16px;
  flex: 1 0 0;
  flex-wrap: wrap;
`

const SystemMessagesContainer = styled.div`
  height: 300px;
  overflow-y: scroll;
  padding-right: 10px;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #888888;
    min-height: 50px;
    border-radius: 10px;
  }
`

type Filter = "all_messages" | "errors_and_warnings" | "unresolved"

function toFilterFunc(filter: Filter): (msgs: SystemMessages) => SystemMessages {
  switch (filter) {
    case "all_messages":
      return (msgs) => msgs
    case "errors_and_warnings":
      return warningAndErrorSystemMessages
    case "unresolved":
      return unresolvedSystemMessages
  }
}

const filterCheckboxText = (filter: Filter, count: number): string => {
  const withCount = (title: string, count: number) => `${title} (${count})`

  switch (filter) {
    case "all_messages": {
      return "All messages"
    }
    case "errors_and_warnings": {
      return withCount("Errors & Warnings", count)
    }
    case "unresolved": {
      return withCount("Unresolved", count)
    }
  }
}

const SystemMessagesCard: React.FunctionComponent = () => {
  const { systemMessages } = useEdgeApi()

  const filters: Filter[] = ["all_messages", "errors_and_warnings", "unresolved"]

  const [selectedFilter, setSelectedFilter] = React.useState<Filter>("all_messages")
  const selectedFilterFunc = toFilterFunc(selectedFilter)

  return (
    <SettingsOverviewCard
      title={SECTION_OVERVIEW_SYSTEM_MESSAGES.name}
      icon={<SystemMessagesIcon />}
      tooltip={SYSTEM_MESSAGES_TOOLTIP}
    >
      <Row nogutter style={{ padding: "0px 16px", gap: "8px 0px" }}>
        {filters.map((filter: Filter) => {
          const filterFunc = toFilterFunc(filter)
          return (
            <Col md={12} lg={12 / filters.length} key={filter}>
              <Row wrap="nowrap" nogutter style={{ gap: "8px" }}>
                <Col style={{ flexGrow: 0 }}>
                  <div
                    onClick={() => {
                      setSelectedFilter(filter)
                    }}
                  >
                    <CheckboxIcon enabled={filter === selectedFilter} />
                  </div>
                </Col>
                <Col style={{ flexGrow: 1 }}>
                  <ButtonTextSmall>{filterCheckboxText(filter, filterFunc(systemMessages).length)}</ButtonTextSmall>
                </Col>
              </Row>
            </Col>
          )
        })}
        <HorizontalSpacer spacing={2.5} />
      </Row>
      <SystemMessagesContainer>
        {selectedFilterFunc(systemMessages).map((systemMessage) => (
          <SystemMessageField key={systemMessage.id} {...systemMessage} />
        ))}
      </SystemMessagesContainer>
      <HorizontalSpacer spacing={2} />
      <ErrorButtonsContainer>
        <Link to="/logs" style={{ textDecoration: "none" }}>
          <PrimaryButton title="Open system logs" iconLeft={<LinkIcon />} variant="dark" />
        </Link>
        {/* TODO unhide after mvp */}
        {/* <PrimaryButton title="Error code index" iconLeft={<InfoIcon />} variant="light" /> */}
      </ErrorButtonsContainer>
    </SettingsOverviewCard>
  )
}

export default SystemMessagesCard
