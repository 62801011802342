import * as React from "react"

interface IconProps {
  color?: string
  opacity?: string
}

export const CheckmarkIcon: React.FunctionComponent<IconProps> = ({ color = "#616161" }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.79995 15.9L4.59995 11.7L3.19995 13.1L8.79995 18.7L20.8 6.69999L19.4 5.29999L8.79995 15.9Z"
      fill={color}
    />
  </svg>
)
