import * as protoFactoryReset from "edge-proto/dist/edge/v1/factory_reset"
import { ProtoMissingFieldError, FactoryResetError } from "./Errors"

export type FactoryResetProgress = "init" | "begin" | "reboot_device" | "unknown"

export interface FactoryResetState {
  progress(): FactoryResetProgress
  update(msg: protoFactoryReset.FactoryResetMessage): FactoryResetState | FactoryResetError | ProtoMissingFieldError
}

export function newFactoryResetState(progress: FactoryResetProgress): FactoryResetState {
  const changeProgress = (progress: FactoryResetProgress) => newFactoryResetState(progress)
  return {
    progress(): FactoryResetProgress {
      return progress
    },
    update(
      factoryResetMessage: protoFactoryReset.FactoryResetMessage,
    ): FactoryResetState | FactoryResetError | ProtoMissingFieldError {
      const message = factoryResetMessage.message
      if (message === undefined) {
        return new ProtoMissingFieldError("FactoryResetMessage", "message")
      }
      switch (message.$case) {
        case "state": {
          const progress = message.state.progress
          switch (progress) {
            case protoFactoryReset.FactoryResetProgress.FACTORY_RESET_PROGRESS_BEGIN:
              return changeProgress("begin")
            case protoFactoryReset.FactoryResetProgress.FACTORY_RESET_PROGRESS_REBOOT_DEVICE:
              return changeProgress("reboot_device")
            case protoFactoryReset.FactoryResetProgress.FACTORY_RESET_PROGRESS_UNSPECIFIED:
              return changeProgress("unknown")
            case protoFactoryReset.FactoryResetProgress.UNRECOGNIZED:
              return changeProgress("unknown")
            default:
              return progress satisfies never
          }
        }
        case "error": {
          const reason = message.error.reason
          const error = (reason: string) =>
            new FactoryResetError(`The factory reset failed since ${reason}: ${message.error.errorMessage}`)
          const errorUnknown = new FactoryResetError(
            `The software update failed for unknown reason: ${message.error.errorMessage}`,
          )
          switch (reason) {
            case protoFactoryReset.FactoryResetErrorReason.FACTORY_RESET_ERROR_REASON_UNSPECIFIED:
              return errorUnknown
            case protoFactoryReset.FactoryResetErrorReason.UNRECOGNIZED:
              return errorUnknown
            case protoFactoryReset.FactoryResetErrorReason.FACTORY_RESET_ERROR_REASON_CONCURRENT_CONFIG_UPDATE:
              return error("a concurrent config update is in progress")
            case protoFactoryReset.FactoryResetErrorReason.FACTORY_RESET_ERROR_REASON_CONCURRENT_FACTORY_RESET:
              return error("a concurrent factory reset is in progress")
            case protoFactoryReset.FactoryResetErrorReason.FACTORY_RESET_ERROR_REASON_CONCURRENT_SOFTWARE_UPDATE:
              return error("a concurrent software update is in progress")
            case protoFactoryReset.FactoryResetErrorReason.FACTORY_RESET_ERROR_REASON_DATABASE_MALFUNCTION:
              return error("the internal database is malfunctioning")
          }
        }
      }
    },
  }
}

export function clientFactoryResetMessage(): protoFactoryReset.ClientFactoryResetMessage {
  return protoFactoryReset.ClientFactoryResetMessage.create({
    updateRequest: {},
  })
}
