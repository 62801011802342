import * as protoSystemMessage from "edge-proto/dist/edge/v1/system_message"
import { ProtoMissingFieldError } from "./Errors"
import dayjs from "dayjs"

export type SystemMessageSeverity = "info" | "warning" | "error"
export type SystemMessageSeverities = SystemMessageSeverity[]

export type ResolveState = { type: "unresolvable" } | { type: "unresolved" } | { type: "resolved"; time: dayjs.Dayjs }

export interface SystemMessage {
  id: number
  time: dayjs.Dayjs
  severity: SystemMessageSeverity
  title: string
  text: string
  resolveState: ResolveState
}

export type SystemMessages = SystemMessage[]

function fromProtoSeverity(severity: protoSystemMessage.Severity): SystemMessageSeverity {
  const defaultSeverity = "info"
  switch (severity) {
    case protoSystemMessage.Severity.SEVERITY_INFO:
      return "info"
    case protoSystemMessage.Severity.SEVERITY_ERROR:
      return "error"
    case protoSystemMessage.Severity.SEVERITY_WARNING:
      return "warning"
    case protoSystemMessage.Severity.SEVERITY_UNSPECIFIED:
      return defaultSeverity
    case protoSystemMessage.Severity.UNRECOGNIZED:
      return defaultSeverity
  }
}

export function unresolvedSystemMessages(systemMessages: SystemMessages): SystemMessages {
  return systemMessages.filter((msg: SystemMessage) => {
    return msg.resolveState.type === "unresolved"
  })
}

export function warningAndErrorSystemMessages(systemMessages: SystemMessages): SystemMessages {
  return systemMessages.filter((msg: SystemMessage) => {
    return msg.severity === "warning" || msg.severity === "error"
  })
}

export function parseSystemMessages(
  systemMessage: protoSystemMessage.SystemMessage,
  prevSystemMessages: SystemMessages,
): SystemMessages | ProtoMissingFieldError {
  const genericMessage = systemMessage.genericMessage
  if (genericMessage === undefined) {
    return new ProtoMissingFieldError("SystemMessage", "generic_message")
  }

  const messageChoice = genericMessage.message
  switch (messageChoice?.$case) {
    case "systemMessage": {
      const msg = messageChoice.systemMessage
      return [
        {
          id: msg.id,
          time: dayjs(msg.timestamp),
          severity: fromProtoSeverity(msg.level),
          title: msg.title,
          text: msg.text,
          resolveState: msg.isResolvable ? { type: "unresolved" } : { type: "unresolvable" },
        },
        ...prevSystemMessages,
      ]
    }
    case "resolveSystemMessage": {
      const resolveMsg = messageChoice.resolveSystemMessage
      return prevSystemMessages.map((msg: SystemMessage) => {
        if (msg.id !== resolveMsg.id) {
          return msg
        }
        return { ...msg, resolveState: { type: "resolved", time: dayjs(resolveMsg.timestamp) } }
      })
    }
    case undefined: {
      return new ProtoMissingFieldError("GenericMessage", "message")
    }
  }
}
