import styled from "styled-components"
import { BaseTextProps, baseTextStyle } from "./types"

const H3 = styled.div<BaseTextProps>`
  ${baseTextStyle(600)}
  font-family: ${(props) => `"${props.monospace ? "RobotoMono" : "Inter"}", "Inter-fallback", "sans-serif"`};
  font-size: 16px;
  font-style: normal;
  line-height: normal;
`

export default H3
