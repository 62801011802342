import * as React from "react"
import SettingsOverviewCard from "../../molecules/SettingsOverviewCard"
import LocationIcon24x24 from "../../atoms/icons/LocationIcon24x24"
import ReadOnlyTextField from "../../molecules/card/ReadOnlyTextField"
import { useEdgeApi } from "../../../edge/EdgeProvider"
import { COORDINATE_DEGREE_DECIMAL_COUNT } from "../../constants"
import { SECTION_OVERVIEW_POSITION } from "../../../utils/pages"
import TextEditor from "../../molecules/inputs/TextEditor"
import ButtonRowEditor from "../../molecules/inputs/ButtonRowEditor"
import { boolToEnumEditor } from "../../../edge/editor"

const POSITION_CARD_TOOLTIP = `
It is recommended to use automatic position. Using manual position is not 
recommended while moving, as it may cause the antenna to be pointed in 
the wrong direction. The number of connected satellites is an indication 
for how reliable the coordinates are. A higher number is better, 3 is minimum 
to receive location, 14 is max.
`

const mappedCoord = (value: number | undefined, mapper: (value?: number) => number | undefined): number | undefined => {
  const mappedValue = mapper(value)
  return mappedValue !== undefined ? mappedValue : value
}

type CoordinateOption = "Automatic" | "Manual"

const PositionCard: React.FunctionComponent = () => {
  const { config, metrics } = useEdgeApi()
  const manualBoolEnabledEditor = config.useManualPositionEnabled("Position")
  const manualEnabledEditor =
    manualBoolEnabledEditor !== undefined
      ? boolToEnumEditor(manualBoolEnabledEditor, "Automatic", "Manual", (value: CoordinateOption) => value)
      : undefined
  const manualEnabled = manualBoolEnabledEditor?.value ?? false
  const option = manualEnabled ? "Manual" : "Automatic"

  const latitudeEditor = config.useManualPositionLat("Position")
  const longitudeEditor = config.useManualPositionLon("Position")

  const longitudeValueMapper = (value?: number) => (value === -180.0 ? 180.0 : undefined)

  const latitude = manualEnabled ? latitudeEditor?.value : metrics.useAntennaPositionLat()
  const longitude = manualEnabled
    ? longitudeEditor?.value
    : mappedCoord(metrics.useAntennaPositionLon(), longitudeValueMapper)

  const latitudeString = latitude !== undefined ? latitude.toFixed(COORDINATE_DEGREE_DECIMAL_COUNT) : undefined
  const longitudeString = longitude !== undefined ? longitude.toFixed(COORDINATE_DEGREE_DECIMAL_COUNT) : undefined

  return (
    <SettingsOverviewCard
      title={SECTION_OVERVIEW_POSITION.name}
      icon={<LocationIcon24x24 />}
      tooltip={POSITION_CARD_TOOLTIP}
    >
      <ButtonRowEditor label="Coordinates" editor={manualEnabledEditor} placeholders={["Automatic", "Manual"]} />
      {option === "Manual" ? (
        <>
          <TextEditor label="Latitude" editor={latitudeEditor} />
          <TextEditor label="Longitude" editor={longitudeEditor} valueMapper={longitudeValueMapper} />
        </>
      ) : (
        <>
          <ReadOnlyTextField label="Latitude" value={latitudeString} placeholder="--- -" />
          <ReadOnlyTextField label="Longitude" value={longitudeString} placeholder="--- -" />
          <ReadOnlyTextField
            label="Connected satellites"
            value={metrics?.useGnssSatellites()?.toString()}
            placeholder="--"
          />
        </>
      )}
    </SettingsOverviewCard>
  )
}

export default PositionCard
