import * as React from "react"
import Card from "../atoms/card/Card"
import styled from "styled-components"
import SettingsCardOverviewBar from "./SettingsOverviewBar"
import { SlideOutHeight } from "../atoms/animations/SlideOutHeight"
import { dashCase } from "../../utils/formatters"

interface SettingsOverviewCardProps {
  title: string
  children: React.ReactNode
  icon: React.ReactNode
  tooltip?: string
}

const CardContentContainer = styled.div`
  padding: 0 2rem 2rem;
`

const SettingsOverviewCard: React.FunctionComponent<SettingsOverviewCardProps> = ({
  title,
  icon,
  children,
  tooltip,
}) => {
  const [open, setOpen] = React.useState(true)

  const toggleOpen = () => {
    setOpen(!open)
  }

  return (
    <Card noPadding id={dashCase(title)} data-testid={`${title}`}>
      <SettingsCardOverviewBar title={title} open={open} onClick={toggleOpen} icon={icon} tooltip={tooltip} />
      <SlideOutHeight isVisible={open}>
        <CardContentContainer>{children}</CardContentContainer>
      </SlideOutHeight>
    </Card>
  )
}

export default SettingsOverviewCard
