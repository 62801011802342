import * as protoLogs from "edge-proto/dist/edge/v1/logs"
import { ProtoMissingFieldError } from "./Errors"
import dayjs from "dayjs"

export type LogLevel = "info" | "warning" | "error"
export type LogLevels = LogLevel[]

export const AllLogLevels: LogLevel[] = ["error", "warning", "info"]

export interface LogLevelMapper {
  toLogLevel: (logLevelDevice: string) => LogLevel
  fromLogLevel: (logLevel: LogLevel) => string[]
}

export interface LogEntry {
  message: string
  time: dayjs.Dayjs
  logLevel: LogLevel
  cursor: string
}

export type LogEntries = LogEntry[]
export type LogServices = string[]

export interface LogsConfig {
  // The log levels to show in the log view. An undefined value means
  // no filter is used.
  logLevelsFilter?: LogLevels
  logServicesFilter?: string[]
  logStartTime?: dayjs.Dayjs
  logEndTime?: dayjs.Dayjs
  // The log search provided from the end-user. Empty string means that no filtered
  // is provided from the end-user.
  logSearch: string
}

const escapeRegex = (str: string) => str.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")

function toggleFilter<T extends string>(filterUndefined: T[] | undefined, value: T, all: T[]): T[] {
  const filter = filterUndefined ?? all
  const idx = filter.indexOf(value)
  if (idx === -1) {
    return [...filter, value]
  } else {
    const filterCopy = [...filter]
    filterCopy.splice(idx, 1)
    return filterCopy
  }
}

export function toggleLogLevel(logsConfig: LogsConfig, logLevel: LogLevel): LogsConfig {
  return { ...logsConfig, logLevelsFilter: toggleFilter(logsConfig.logLevelsFilter, logLevel, AllLogLevels) }
}

export function toggleService(logsConfig: LogsConfig, service: string, allServices: string[]): LogsConfig {
  return { ...logsConfig, logServicesFilter: toggleFilter(logsConfig.logServicesFilter, service, allServices) }
}

export function isLogLevelActive(logsConfig: LogsConfig, logLevel: LogLevel): boolean {
  return logsConfig.logLevelsFilter?.indexOf(logLevel) !== -1
}

export function isServiceActive(logsConfig: LogsConfig, service: string): boolean {
  return logsConfig.logServicesFilter?.indexOf(service) !== -1
}

export function setSearch(logsConfig: LogsConfig, logSearch: string): LogsConfig {
  return { ...logsConfig, logSearch }
}

export function applySearchFilter(logsConfig: LogsConfig, logMessages: LogEntry[]): LogEntry[] {
  const re = new RegExp(escapeRegex(logsConfig.logSearch), "i")
  return logMessages.filter((logEntry: LogEntry) => re.test(logEntry.message))
}

/**
 * The LogsConfig interface encapsulates the filter functionality. However, the
 * search filtering of messages is executed in the front-end. Thus, when the search
 * input changes we don't have to open a new ws connection. This function simply
 * returns true if a complete new websocket is required to execute the filtering,
 * else false.
 */
export function requiresNewWsConnection(oldLogsConfig?: LogsConfig, newLogsConfig?: LogsConfig): boolean {
  if (oldLogsConfig === undefined) {
    return newLogsConfig !== undefined
  }
  if (newLogsConfig === undefined) {
    // In reality we should close the websocket connection here, and not open
    // another, so returning true here is a little bit confusing.
    return true
  }
  // Not the fastest way to check if two sets are equal, but the sets are not big.
  const listEqual = <T extends string>(l1?: T[], l2?: T[]): boolean => {
    if (l1 === undefined) {
      return l2 === undefined
    }
    if (l2 === undefined) {
      return true
    }
    return l1.sort().toString() === l2.sort().toString()
  }

  return (
    !listEqual(oldLogsConfig.logLevelsFilter, newLogsConfig.logLevelsFilter) ||
    !listEqual(oldLogsConfig.logServicesFilter, newLogsConfig.logServicesFilter) ||
    oldLogsConfig.logStartTime !== newLogsConfig.logStartTime ||
    oldLogsConfig.logEndTime !== newLogsConfig.logEndTime
  )
}

/**
 * Returns true if filter will result in zero log entries, for example if no
 * log levels are selected or no log service are selected.
 */
export function isNullFilter(logsConfig: LogsConfig): boolean {
  const isEmptyList = <T extends string>(list?: T[]): boolean => {
    return list === undefined ? false : list.length === 0
  }
  return isEmptyList(logsConfig.logLevelsFilter) || isEmptyList(logsConfig.logServicesFilter)
}

/**
 * All is a virtual filter meaning no filter is applied. It returns true if
 * all log levels and services should be sent in the log query.
 *
 * @returns true if no filter is used, that is all log levels and services are
 * returned, else false.
 */
export function isAllEnabled(logsConfig: LogsConfig, allServices: string[]): boolean {
  return (
    allServices.every((service: string) => isServiceActive(logsConfig, service)) &&
    AllLogLevels.every((logLevel: LogLevel) => isLogLevelActive(logsConfig, logLevel))
  )
}

/**
 * Toggles the state between "no filter" and "full filter". If full filter is
 * used, no queries are returned since no log levels and no services are enabled.
 */
export function toggleAll(logsConfig: LogsConfig, allServices: string[]): LogsConfig {
  if (isAllEnabled(logsConfig, allServices)) {
    return { ...logsConfig, logLevelsFilter: [], logServicesFilter: [] }
  } else {
    return { ...logsConfig, logLevelsFilter: undefined, logServicesFilter: undefined }
  }
}

export function initLogsConfig(): LogsConfig {
  return {
    logLevelsFilter: undefined,
    logServicesFilter: undefined,
    logSearch: "",
  }
}

export function parseLogEntryMessage(
  logEntryMessage: protoLogs.LogEntryMessage,
  logLevelMapper: (id: string) => LogLevel,
): LogEntry | ProtoMissingFieldError {
  const logEntry = logEntryMessage.logEntry
  if (logEntry === undefined) {
    return new ProtoMissingFieldError("LogEntryMessage", "log_entry")
  }
  const timestamp = logEntry.timestamp
  if (timestamp === undefined) {
    return new ProtoMissingFieldError("LogEntry", "timestamp")
  }
  const cursor = logEntry.cursor
  if (cursor === undefined) {
    return new ProtoMissingFieldError("LogEntry", "cursor")
  }
  return {
    message: logEntry.payload,
    time: dayjs(timestamp.toString()),
    logLevel: logLevelMapper(logEntry.logLevel),
    cursor: logEntry.cursor,
  }
}
