import * as React from "react"
import { Col, Row } from "react-grid-system"
import { styled } from "styled-components"
import { useEdgeApi } from "../../edge/EdgeProvider"
import {
  AllLogLevels,
  initLogsConfig,
  isLogLevelActive,
  isServiceActive,
  LogLevel,
  LogsConfig,
  toggleAll,
  isAllEnabled,
  toggleLogLevel,
  toggleService,
  setSearch,
} from "../../edge/Logs"
import Card from "../atoms/card/Card"
import PrimaryButton from "../atoms/buttons/PrimaryButton"
import LogsIcon from "../atoms/icons/LogsIcon"
import ArrowBackIcon from "../atoms/icons/ArrayBackIcon"
import PageContainer from "../atoms/PageContainer"
import SearchField from "../atoms/inputs/SearchField"
import BodyText from "../atoms/typography/BodyText"
import ButtonTextSmall from "../atoms/typography/ButtonTextSmall"
import H3 from "../atoms/typography/H3"
import { HelpIconOverviewBar } from "../molecules/SettingsOverviewBar"
import { theme } from "../theme"
import { capitalizeFirstLetter } from "../../utils/formatters"
import { CheckboxIcon } from "../atoms/icons/CheckboxIcon"
import { LogServiceMetadata } from "../../edge/DeviceMetadata"
import { Link } from "react-router-dom"
import { Spacer } from "../atoms/spacers"
import DownloadIcon from "../atoms/icons/DownloadIcon"
import * as Aria from "react-aria-components"
import { Breakpoints } from "../../utils/design-helpers"
import DatePickerField from "../atoms/inputs/DatePickerField"
import dayjs from "dayjs"

const MONTH_CHARACTER_COUNT = 3
const SCROLL_PAGINATION_TOP_MARGINAL = 5

const LogContainer = styled(Card)`
  display: flex;
  height: 488px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  margin-bottom: 32px;

  overflow-y: scroll;
  flex-direction: column-reverse;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #888888;
    min-height: 50px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    margin-top: 24px;
    margin-bottom: 24px;
  }
`

const LogEntryContainer = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  align-content: center;
  gap: 4px 16px;
  align-self: stretch;
  flex-wrap: wrap;
`

const LogVerticalNavbarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: clip;
  margin-bottom: 32px;
`

const LogOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  padding-bottom: 1rem;
`

const AlignRightSideContainer = styled.div`
  @media (min-width: ${Breakpoints.SM}px) {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
  }
`

const DatePickerRangeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: nowrap;
`

function logLevelToColor(logLevel: LogLevel): string {
  switch (logLevel) {
    case "info": {
      return theme.colors.greyDarker
    }
    case "warning": {
      return theme.colors.orange
    }
    case "error": {
      return theme.colors.redDark
    }
  }
}

export const LogsPageVerticalBarContent: React.FunctionComponent<{
  logsConfig?: LogsConfig
  setLogsConfig: (logsPageConfig?: LogsConfig) => void
}> = ({ logsConfig, setLogsConfig }) => {
  const { metadata } = useEdgeApi()
  const services = metadata?.logs.services ?? []
  const serviceIds = metadata?.logs.services.map((service) => service.id) ?? []

  React.useEffect(() => {
    setLogsConfig(initLogsConfig())
    return () => {
      setLogsConfig(undefined)
    }
  }, [])

  if (logsConfig === undefined) {
    return <></>
  }

  const checkBoxSection = <T,>(
    title: string | undefined,
    checkboxes: T[],
    key: (id: T) => string,
    display: (id: T) => string,
    isEnabled: (id: T) => boolean,
    onClick: (id: T) => void,
  ) => {
    return (
      <>
        {title && <BodyText color={theme.colors.white}>{title}</BodyText>}
        {checkboxes.map((id: T) => (
          <Row nogutter key={key(id)} style={{ gap: "8px" }}>
            <Col style={{ flexGrow: 0 }}>
              <div onClick={() => onClick(id)}>
                <CheckboxIcon enabled={isEnabled(id)} />
              </div>
            </Col>
            <Col style={{ flexGrow: 1 }}>
              <ButtonTextSmall color={theme.colors.white}>{display(id)}</ButtonTextSmall>
            </Col>
          </Row>
        ))}
      </>
    )
  }

  return (
    <LogVerticalNavbarContainer>
      <Row wrap="nowrap" nogutter>
        <Link to="/" style={{ textDecoration: "none" }}>
          <PrimaryButton title="Go back" variant="light" size="small" iconLeft={<ArrowBackIcon />} />
        </Link>
      </Row>
      <Row wrap="nowrap" nogutter>
        <LogOptionsContainer>
          {checkBoxSection(
            undefined,
            ["All logs"],
            (id: string) => id,
            (id: string) => id,
            (_: string) => isAllEnabled(logsConfig, serviceIds),
            (_: string) => setLogsConfig(toggleAll(logsConfig, serviceIds)),
          )}
          {checkBoxSection(
            "Severity",
            AllLogLevels,
            (id: LogLevel) => id,
            (id: LogLevel) => capitalizeFirstLetter(id),
            (logLevel: LogLevel) => isLogLevelActive(logsConfig, logLevel),
            (logLevel: LogLevel) => setLogsConfig(toggleLogLevel(logsConfig, logLevel)),
          )}
          {checkBoxSection(
            "Category",
            metadata !== undefined
              ? services.sort((s1, s2) => (s1.readableName.toLowerCase() > s2.readableName.toLowerCase() ? 1 : -1))
              : [],
            (service: LogServiceMetadata) => service.id,
            (service: LogServiceMetadata) => capitalizeFirstLetter(service.readableName),
            (service: LogServiceMetadata) => isServiceActive(logsConfig, service.id),
            (service: LogServiceMetadata) => {
              setLogsConfig(
                metadata &&
                  toggleService(
                    logsConfig,
                    service.id,
                    metadata.logs.services.map((service) => service.id),
                  ),
              )
            },
          )}
        </LogOptionsContainer>
      </Row>
    </LogVerticalNavbarContainer>
  )
}

export const DatePickerRange: React.FunctionComponent<{
  logsConfig: LogsConfig | undefined
  setLogsConfig: (logsConfig?: LogsConfig) => void
}> = ({ logsConfig, setLogsConfig }) => {
  const [startDateTime, setStartDateTime] = React.useState<Aria.DateValue | null>(null)
  const [endDateTime, setEndDateTime] = React.useState<Aria.DateValue | null>(null)

  const onChangeStartTime = (date: Aria.DateValue | null) => {
    if (logsConfig === undefined) return
    setStartDateTime(date)
    const time = date !== null ? dayjs(date.toString()) : undefined
    setLogsConfig({ ...logsConfig, logStartTime: time })
  }

  const onChangeEndTime = (date: Aria.DateValue | null) => {
    if (logsConfig === undefined) return
    setEndDateTime(date)
    const time = date !== null ? dayjs(date.toString()) : undefined
    setLogsConfig({ ...logsConfig, logEndTime: time })
  }

  return (
    <DatePickerRangeContainer>
      <DatePickerField
        value={startDateTime}
        onChange={onChangeStartTime}
        onClear={() => {
          onChangeStartTime(null)
        }}
      />
      <H3>-</H3>
      <DatePickerField
        value={endDateTime}
        onChange={onChangeEndTime}
        onClear={() => {
          onChangeEndTime(null)
        }}
      />
    </DatePickerRangeContainer>
  )
}

const LogsPage: React.FunctionComponent<{
  logsConfig?: LogsConfig
  setLogsConfig: (logsConfig?: LogsConfig) => void
}> = ({ logsConfig, setLogsConfig }) => {
  const { logs, logsPaginate, downloadLogs } = useEdgeApi()

  const parentRef = React.useRef<HTMLDivElement>(null)

  const [paginateCursor, setPaginateCursor] = React.useState<string | undefined>(undefined)

  React.useEffect(() => {
    const handleScroll = () => {
      if (!parentRef.current) return
      const { scrollTop, scrollHeight, clientHeight } = parentRef.current
      if (scrollHeight - SCROLL_PAGINATION_TOP_MARGINAL <= clientHeight - scrollTop) {
        if (logs.length === 0) return
        const lastMsg = logs[logs.length - 1]
        if (lastMsg.cursor === paginateCursor) return
        setPaginateCursor(lastMsg.cursor)
        logsPaginate()
      }
    }

    parentRef.current?.addEventListener("scroll", handleScroll)
    return () => parentRef.current?.removeEventListener("scroll", handleScroll)
  }, [logs.length, logsPaginate, paginateCursor])

  return (
    <PageContainer>
      <Row
        nogutter
        justify="between"
        align="center"
        style={{ gap: "32px 0px", padding: "32px 8px 0px 8px", flexGrow: 1 }}
      >
        <Col xs={12} md={6}>
          <HelpIconOverviewBar
            title="System Logs"
            icon={<LogsIcon />}
            rightComponent={<></>}
            tooltip="Logs for internal system services"
            rowStyle={{ padding: "0 16px", justifyContent: "space-between" }}
          />
        </Col>
        <Col xs={12} md={6}>
          <AlignRightSideContainer>
            <PrimaryButton
              title="Download logs"
              size={"small"}
              iconLeft={<DownloadIcon color={theme.colors.white} />}
              onClick={() => downloadLogs()}
            />
          </AlignRightSideContainer>
        </Col>
      </Row>
      <Row nogutter justify="between" align="center" style={{ gap: "32px 0px", padding: "32px 8px 32px 8px" }}>
        <Col xs={12} md={3}>
          <SearchField
            onChange={(searchInput: string) => {
              if (logsConfig === undefined) return
              setLogsConfig(setSearch(logsConfig, searchInput))
            }}
          />
        </Col>
        <Col xs={12} md={6}>
          <AlignRightSideContainer>
            <DatePickerRange logsConfig={logsConfig} setLogsConfig={setLogsConfig} />
          </AlignRightSideContainer>
        </Col>
      </Row>
      <Spacer spacing={10} />
      <LogContainer ref={parentRef}>
        {logs.map((logEntry) => (
          <LogEntryContainer key={logEntry.cursor}>
            {" "}
            <BodyText color={theme.colors.greyDarker} monospace={true}>
              {logEntry.time.format("HH:mm:ss")}
            </BodyText>
            <BodyText color={theme.colors.greyDarker} style={{ flexShrink: 0 }} monospace={true}>
              {logEntry.time.format("DD")} {logEntry.time.format("MMMM").substring(0, MONTH_CHARACTER_COUNT)}{" "}
              {logEntry.time.format("YYYY")}
            </BodyText>
            <H3 style={{ overflowWrap: "break-word" }} color={logLevelToColor(logEntry.logLevel)} monospace={true}>
              {logEntry.message}
            </H3>
          </LogEntryContainer>
        ))}
      </LogContainer>
    </PageContainer>
  )
}

export default LogsPage
