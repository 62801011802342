import React from "react"
import styled from "styled-components"
import { forwardRef } from "react"
import * as Aria from "react-aria-components"
import { theme } from "../../theme"
import { CrossIcon } from "../icons/CrossIcon"
import SearchIcon from "../icons/SearchIcon"
import H3 from "../typography/H3"

export interface SearchFieldProps extends Aria.SearchFieldProps, Pick<Aria.InputProps, "placeholder"> {
  label?: string
}

const SearchFieldStyle = styled(Aria.SearchField)`
  display: grid;
  row-gap: 1rem;

  &[data-disabled] {
    color: ${({ theme }) => theme.colors.greyDisabled};
  }

  & .search-icon,
  &[data-empty] .cross-icon {
    display: none;
  }

  &[data-empty] .search-icon,
  & .cross-icon {
    display: unset;
  }

  &:not([data-disabled]) .cross-icon {
    cursor: pointer;
  }
`

const SearchFieldButtonStyle = styled(Aria.Button)`
  border: none;
  background: none;
  padding: 0;
  display: grid;
`

const SearchFieldContentStyle = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom-style: solid;
  border-bottom-color: inherit;
  border-bottom-width: 3px;
  padding-bottom: 6px;
  color: ${({ theme }) => theme.colors.greyDark};
`

const InputStyle = styled(H3)`
  border: none;
  outline: none;
  background: none;
  color: inherit;
  padding: 0;

  &::placeholder {
    color: ${({ theme }) => theme.colors.greyDisabled};
  }

  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration {
    appearance: none;
  }
`

const LabelStyle = styled(H3)`
  color: inherit;
`

const SearchField = forwardRef<HTMLInputElement, SearchFieldProps>(
  ({ label, placeholder = "Search", ...props }, ref) => {
    return (
      <SearchFieldStyle {...props}>
        {label && <LabelStyle as={Aria.Label}>{label}</LabelStyle>}
        <SearchFieldContentStyle>
          <InputStyle as={Aria.Input} ref={ref} placeholder={placeholder} size={10} />
          <SearchFieldButtonStyle>
            <SearchIcon color={theme.colors.greyDark} />
            <CrossIcon color={theme.colors.greyDark} size={18} />
          </SearchFieldButtonStyle>
        </SearchFieldContentStyle>
        <H3 color={theme.colors.red} as={Aria.FieldError} />
      </SearchFieldStyle>
    )
  },
)

SearchField.displayName = "SearchField"

export default SearchField
