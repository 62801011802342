import React from "react"
import styled from "styled-components"
import { forwardRef } from "react"
import * as Aria from "react-aria-components"
import CalenderIcon from "../icons/CalenderIcon"
import H3 from "../typography/H3"
import { theme } from "../../theme"
import PrimaryButton from "../buttons/PrimaryButton"

interface Props<T extends Aria.DateValue> extends Aria.DatePickerProps<T> {
  onClear: () => void
}

const ContentStyle = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom-style: solid;
  border-bottom-color: inherit;
  border-bottom-width: 3px;
  color: ${({ theme }) => theme.colors.greyDark};
`

const DatePickerFieldStyle = styled(Aria.DatePicker)`
  & .react-aria-Group {
    display: flex;
    width: fit-content;
    align-items: center;
  }

  & .react-aria-DateInput {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 4px 40px 4px 0px;
  }

  & .react-aria-Button {
    forced-color-adjust: none;
    border-radius: 4px;
    border: none;
    margin-left: -1.929rem;
    width: 1.429rem;
    height: 1.429rem;
    padding: 0;
    font-size: 0.857rem;
    box-sizing: content-box;

    &[data-pressed] {
      box-shadow: none;
    }

    &[data-focus-visible] {
      outline-offset: 2px;
    }
  }

  /* Creates whitespace before the hour text */
  div[data-type="hour"]::before {
    content: " ";
    white-space: pre;
  }
`

const CalendarStyle = styled(Aria.Calendar)`
  margin-top: 16px;
  border-color: #000000;
  border-radius: 5px;
  border: 1px solid;
  border-color: ${theme.colors.greyDark};
  background-color: #ffffff;
  padding-bottom: 16px;

  width: 300px;

  div[data-outside-visible-range="true"] {
    content-visibility: hidden;
  }

  & div,
  th,
  h2,
  button {
    font-family: Karla;
    color: ${theme.colors.greyDark};
  }

  & header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 20px;
    margin-left: 20px;
  }

  & .react-aria-Heading {
    font-size: 20px;
  }

  & .react-aria-CalendarGrid {
    border-collapse: separate;
    text-indent: initial;
    border-spacing: 2px;
    width: 100%;
  }

  & .react-aria-CalendarGridHeader {
  }

  & .react-aria-CalendarGridBody {
  }

  & .react-aria-CalendarHeaderCell {
    font-weight: bold;
    text-align: center;
  }

  & .react-aria-CalendarCell {
    text-align: center;
  }

  & .react-aria-Button {
    background-color: ${theme.colors.white};
    border: 0;
  }
`

const DatePickerFieldWithRef = <T extends Aria.DateValue>(
  { onClear, ...props }: Props<T>,
  ref: React.Ref<HTMLInputElement>,
) => {
  return (
    <DatePickerFieldStyle granularity="minute" {...(props as any)} ref={ref}>
      <ContentStyle>
        <Aria.Group>
          <Aria.TimeField />
        </Aria.Group>
        <Aria.Group>
          <Aria.DateInput>{(segment) => <H3 as={Aria.DateSegment} segment={segment} />}</Aria.DateInput>
          <Aria.Button style={{ background: "none" }}>
            <CalenderIcon />
          </Aria.Button>
        </Aria.Group>
      </ContentStyle>
      <Aria.Popover>
        <Aria.Dialog>
          <CalendarStyle>
            <header>
              <Aria.Button slot="previous">◀</Aria.Button>
              <Aria.Heading />
              <Aria.Button slot="next">▶</Aria.Button>
            </header>
            <Aria.CalendarGrid>{(date) => <Aria.CalendarCell date={date} />}</Aria.CalendarGrid>
            <div style={{ marginTop: "8px", marginLeft: "16px" }}>
              <PrimaryButton variant="light" title="Clear" size="small" onClick={() => onClear()} />
            </div>
          </CalendarStyle>
        </Aria.Dialog>
      </Aria.Popover>
    </DatePickerFieldStyle>
  )
}

export const DatePickerField = forwardRef(DatePickerFieldWithRef) as <T extends Aria.DateValue>(
  props: Props<T> & { ref?: React.Ref<HTMLInputElement> },
) => JSX.Element

export default DatePickerField
