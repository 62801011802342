import * as React from "react"
import Modal from "../../molecules/modals/Modal"
import { AnimatePresence } from "framer-motion"
import { Row, Col } from "react-grid-system"
import { HorizontalSpacer } from "../../atoms/spacers"
import BodyTextLarge from "../../atoms/typography/BodyTextLarge"
import SpinnerIcon from "../../atoms/icons/SpinnerIcon"
import { FactoryResetState } from "../../../edge/FactoryReset"
import { theme } from "../../theme"

interface FactoryResetModalProps {
  factoryResetState: FactoryResetState
}

const stateToText = (factoryResetState: FactoryResetState): string => {
  switch (factoryResetState.progress()) {
    case "init":
      return "Connecting to server.."
    case "begin":
      return "Initializing factory reset.."
    case "reboot_device":
      return "Rebooting device.."
    case "unknown":
      return "Applying factory reset.."
  }
}

const FactoryResetProgressModal: React.FunctionComponent<FactoryResetModalProps> = ({ factoryResetState }) => {
  const onClose = () => {
    // We do not allow to close the modal during the factory reset
  }

  return (
    <AnimatePresence mode="wait">
      <Modal title={"Applying factory reset"} leftIcon={<SpinnerIcon />} onClose={onClose} centerTitle={true}>
        <HorizontalSpacer spacing={2} />
        <Row>
          <Col>
            <BodyTextLarge color={theme.colors.greyDarker} textAlign="center">
              {stateToText(factoryResetState)}
            </BodyTextLarge>
          </Col>
        </Row>
        <HorizontalSpacer spacing={2} />
        <Row>
          <Col>
            <BodyTextLarge textAlign="center">
              This may take a few minutes after which the terminal will restart. Make sure the terminal is connected to
              a power source for the duration of the factory reset.
            </BodyTextLarge>
          </Col>
        </Row>
      </Modal>
    </AnimatePresence>
  )
}

export default FactoryResetProgressModal
