import * as React from "react"

interface IconProps {
  state: "on" | "pending" | "off"
}

const TxIcon: React.FunctionComponent<IconProps> = ({ state }) => {
  switch (state) {
    case "on":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path
            d="M6.57464 4.57422L8.46797 6.46755C6.53464 8.38755 5.33464 11.0542 5.33464 14.0009C5.33464 16.9476 6.53464 19.6142 8.46797 21.5342L6.57464 23.4276C4.1613 21.0142 2.66797 17.6809 2.66797 14.0009C2.66797 10.3209 4.1613 6.98755 6.57464 4.57422Z"
            fill="white"
          />
          <path
            d="M26.668 14.0009C26.668 16.9476 25.468 19.6142 23.5346 21.5342L25.428 23.4276C27.8413 21.0142 29.3346 17.6809 29.3346 14.0009C29.3346 10.3209 27.8413 6.98755 25.428 4.57422L23.5346 6.46755C25.468 8.38755 26.668 11.0542 26.668 14.0009Z"
            fill="white"
          />
          <path
            d="M8.0013 14.0009C8.0013 16.2142 8.89464 18.2142 10.348 19.6542L12.228 17.7742C11.268 16.8009 10.668 15.4676 10.668 14.0009C10.668 12.5342 11.268 11.2009 12.2413 10.2409L10.348 8.34755C8.89464 9.78755 8.0013 11.7876 8.0013 14.0009Z"
            fill="white"
          />
          <path
            d="M24.0013 14.0009C24.0013 16.2142 23.108 18.2142 21.6546 19.6542L19.7746 17.7742C20.7346 16.8009 21.3346 15.4676 21.3346 14.0009C21.3346 12.5342 20.7346 11.2009 19.7613 10.2409L21.6546 8.34755C23.108 9.78755 24.0013 11.7876 24.0013 14.0009Z"
            fill="white"
          />
          <path
            d="M17.3346 15.9161C17.9391 15.4944 18.3346 14.7939 18.3346 14.001C18.3346 12.7123 17.29 11.6676 16.0013 11.6676C14.7126 11.6676 13.668 12.7123 13.668 14.001C13.668 14.7939 14.0635 15.4944 14.668 15.9161V28.001H17.3346V15.9161Z"
            fill="white"
          />
          <path
            d="M6.57464 4.57422L8.46797 6.46755C6.53464 8.38755 5.33464 11.0542 5.33464 14.0009C5.33464 16.9476 6.53464 19.6142 8.46797 21.5342L6.57464 23.4276C4.1613 21.0142 2.66797 17.6809 2.66797 14.0009C2.66797 10.3209 4.1613 6.98755 6.57464 4.57422Z"
            fill="#F0C93E"
          />
          <path
            d="M26.668 14.0009C26.668 16.9476 25.468 19.6142 23.5346 21.5342L25.428 23.4276C27.8413 21.0142 29.3346 17.6809 29.3346 14.0009C29.3346 10.3209 27.8413 6.98755 25.428 4.57422L23.5346 6.46755C25.468 8.38755 26.668 11.0542 26.668 14.0009Z"
            fill="#F0C93E"
          />
          <path
            d="M8.0013 14.0009C8.0013 16.2142 8.89464 18.2142 10.348 19.6542L12.228 17.7742C11.268 16.8009 10.668 15.4676 10.668 14.0009C10.668 12.5342 11.268 11.2009 12.2413 10.2409L10.348 8.34755C8.89464 9.78755 8.0013 11.7876 8.0013 14.0009Z"
            fill="#F0C93E"
          />
          <path
            d="M24.0013 14.0009C24.0013 16.2142 23.108 18.2142 21.6546 19.6542L19.7746 17.7742C20.7346 16.8009 21.3346 15.4676 21.3346 14.0009C21.3346 12.5342 20.7346 11.2009 19.7613 10.2409L21.6546 8.34755C23.108 9.78755 24.0013 11.7876 24.0013 14.0009Z"
            fill="#F0C93E"
          />
        </svg>
      )
    case "pending":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path
            d="M6.57464 4.57422L8.46797 6.46755C6.53464 8.38755 5.33464 11.0542 5.33464 14.0009C5.33464 16.9476 6.53464 19.6142 8.46797 21.5342L6.57464 23.4276C4.1613 21.0142 2.66797 17.6809 2.66797 14.0009C2.66797 10.3209 4.1613 6.98755 6.57464 4.57422Z"
            fill="white"
          />
          <path
            d="M26.668 14.0009C26.668 16.9476 25.468 19.6142 23.5346 21.5342L25.428 23.4276C27.8413 21.0142 29.3346 17.6809 29.3346 14.0009C29.3346 10.3209 27.8413 6.98755 25.428 4.57422L23.5346 6.46755C25.468 8.38755 26.668 11.0542 26.668 14.0009Z"
            fill="white"
          />
          <path
            d="M8.0013 14.0009C8.0013 16.2142 8.89464 18.2142 10.348 19.6542L12.228 17.7742C11.268 16.8009 10.668 15.4676 10.668 14.0009C10.668 12.5342 11.268 11.2009 12.2413 10.2409L10.348 8.34755C8.89464 9.78755 8.0013 11.7876 8.0013 14.0009Z"
            fill="white"
          />
          <path
            d="M24.0013 14.0009C24.0013 16.2142 23.108 18.2142 21.6546 19.6542L19.7746 17.7742C20.7346 16.8009 21.3346 15.4676 21.3346 14.0009C21.3346 12.5342 20.7346 11.2009 19.7613 10.2409L21.6546 8.34755C23.108 9.78755 24.0013 11.7876 24.0013 14.0009Z"
            fill="white"
          />
          <path
            d="M17.3346 15.9161C17.9391 15.4944 18.3346 14.7939 18.3346 14.001C18.3346 12.7123 17.29 11.6676 16.0013 11.6676C14.7126 11.6676 13.668 12.7123 13.668 14.001C13.668 14.7939 14.0635 15.4944 14.668 15.9161V28.001H17.3346V15.9161Z"
            fill="white"
          />
          <path
            d="M6.57464 4.57422L8.46797 6.46755C6.53464 8.38755 5.33464 11.0542 5.33464 14.0009C5.33464 16.9476 6.53464 19.6142 8.46797 21.5342L6.57464 23.4276C4.1613 21.0142 2.66797 17.6809 2.66797 14.0009C2.66797 10.3209 4.1613 6.98755 6.57464 4.57422Z"
            fill="#616161"
          />
          <path
            d="M26.668 14.0009C26.668 16.9476 25.468 19.6142 23.5346 21.5342L25.428 23.4276C27.8413 21.0142 29.3346 17.6809 29.3346 14.0009C29.3346 10.3209 27.8413 6.98755 25.428 4.57422L23.5346 6.46755C25.468 8.38755 26.668 11.0542 26.668 14.0009Z"
            fill="#616161"
          />
          <path
            d="M8.0013 14.0009C8.0013 16.2142 8.89464 18.2142 10.348 19.6542L12.228 17.7742C11.268 16.8009 10.668 15.4676 10.668 14.0009C10.668 12.5342 11.268 11.2009 12.2413 10.2409L10.348 8.34755C8.89464 9.78755 8.0013 11.7876 8.0013 14.0009Z"
            fill="#616161"
          />
          <path
            d="M24.0013 14.0009C24.0013 16.2142 23.108 18.2142 21.6546 19.6542L19.7746 17.7742C20.7346 16.8009 21.3346 15.4676 21.3346 14.0009C21.3346 12.5342 20.7346 11.2009 19.7613 10.2409L21.6546 8.34755C23.108 9.78755 24.0013 11.7876 24.0013 14.0009Z"
            fill="#616161"
          />
        </svg>
      )
    case "off":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path
            d="M1.91797 3.67859L3.80359 1.79297L28.2363 26.2256L26.3506 28.1113L17.3327 19.0933V28.0026H14.666V16.4266L14.2523 16.0129C14.1585 15.9313 14.0705 15.8432 13.9888 15.7495L10.8513 12.6119C10.7304 13.0555 10.666 13.5218 10.666 14.0024C10.666 15.4691 11.266 16.8024 12.226 17.7757L10.346 19.6557C8.89268 18.2157 7.99935 16.2157 7.99935 14.0024C7.99935 12.7619 8.27994 11.5885 8.78246 10.5431L6.81881 8.57943C5.87537 10.1658 5.33268 12.0208 5.33268 14.0024C5.33268 16.9491 6.53268 19.6157 8.46602 21.5357L6.57268 23.4291C4.15935 21.0157 2.66602 17.6824 2.66602 14.0024C2.66602 11.2825 3.48176 8.75204 4.88177 6.64239L1.91797 3.67859Z"
            fill="white"
          />
          <path
            d="M21.1563 15.3616L23.2292 17.4345C23.7236 16.396 23.9993 15.2321 23.9993 14.0024C23.9993 11.7891 23.106 9.78906 21.6527 8.34906L19.7593 10.2424C20.7327 11.2024 21.3327 12.5357 21.3327 14.0024C21.3327 14.4716 21.2713 14.9271 21.1563 15.3616Z"
            fill="white"
          />
          <path
            d="M27.1329 21.3382L25.1948 19.4001C26.1291 17.8193 26.666 15.9735 26.666 14.0024C26.666 11.0557 25.466 8.38906 23.5327 6.46906L25.426 4.57572C27.8393 6.98906 29.3327 10.3224 29.3327 14.0024C29.3327 16.7119 28.5232 19.2334 27.1329 21.3382Z"
            fill="white"
          />
          <path
            d="M21.1548 15.3601L23.2277 17.433C23.7221 16.3945 23.9978 15.2306 23.9978 14.0009C23.9978 11.7876 23.1045 9.78755 21.6511 8.34755L19.7578 10.2409C20.7311 11.2009 21.3311 12.5342 21.3311 14.0009C21.3311 14.4701 21.2697 14.9256 21.1548 15.3601Z"
            fill="#616161"
          />
          <path
            d="M27.1314 21.3367L25.1933 19.3986C26.1275 17.8178 26.6645 15.972 26.6645 14.0009C26.6645 11.0542 25.4645 8.38755 23.5311 6.46755L25.4245 4.57422C27.8378 6.98755 29.3311 10.3209 29.3311 14.0009C29.3311 16.7104 28.5216 19.2319 27.1314 21.3367Z"
            fill="#616161"
          />
          <path
            d="M10.8494 12.6102C10.7285 13.0537 10.6641 13.52 10.6641 14.0006C10.6641 15.4673 11.2641 16.8006 12.2241 17.774L10.3441 19.654C8.89073 18.214 7.9974 16.214 7.9974 14.0006C7.9974 12.7602 8.27799 11.5867 8.78051 10.5413L10.8494 12.6102Z"
            fill="#616161"
          />
          <path
            d="M6.81685 8.57766C5.87341 10.1641 5.33073 12.019 5.33073 14.0006C5.33073 16.9473 6.53073 19.614 8.46406 21.534L6.57073 23.4273C4.1574 21.014 2.66406 17.6806 2.66406 14.0006C2.66406 11.2808 3.47981 8.75027 4.87982 6.64062L6.81685 8.57766Z"
            fill="#616161"
          />
        </svg>
      )
  }
}

export default TxIcon
