import * as React from "react"
import StatusIcon from "./icons/StatusIcon"
import { Spacer } from "./spacers"
import BodyText from "./typography/BodyText"
import { capitalizeFirstLetter } from "../../utils/formatters"
import styled from "styled-components"
import { TerminalStatusState } from "../../types"

interface TerminalStatusProps {
  status: TerminalStatusState
}

const TerminalStatusContainer = styled.div`
  display: flex;
  align-items: center;
`

const terminalStatusToString = (statusState: TerminalStatusState): string => {
  switch (statusState) {
    case "online":
      return "online"
    case "connecting":
      return "connecting..."
    case "error":
      return "error"
    case "connecting_to_device":
      return "connecting to device..."
  }
}

const TerminalStatus: React.FunctionComponent<TerminalStatusProps> = ({ status }) => {
  return (
    <TerminalStatusContainer>
      <StatusIcon status={status} />
      <Spacer spacing={0.5} />
      <BodyText color="white">{capitalizeFirstLetter(terminalStatusToString(status))}</BodyText>
    </TerminalStatusContainer>
  )
}

export default TerminalStatus
