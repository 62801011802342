import * as React from "react"
import { theme } from "../../theme"

interface IconProps {
  color?: string
  size?: number
}

export const CrossIcon: React.FunctionComponent<IconProps> = ({ color = theme.colors.greyDark, size = 24 }) => (
  <svg
    data-testid="cross"
    width={`${size}`}
    height={`${size}`}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="cross-icon"
  >
    <path
      d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
      fill={color}
    />
  </svg>
)
