import * as React from "react"
import styled from "styled-components"
import { theme } from "../../components/theme"
import H2 from "../atoms/typography/H2"
import { ArrowDropDownIcon } from "../atoms/icons/ArrowDropDownIcon"
import { Spacer } from "../atoms/spacers"
import { Row, Col } from "react-grid-system"
import CardBar from "../atoms/card/CardBar"
import TooltipRow from "./card/TooltipRow"
import IconContainer from "../atoms/icons/IconContainer"

interface SettingsOverviewBarProps {
  title: string
  open: boolean
  onClick: () => void
  icon: React.ReactNode
  tooltip?: string
}

interface HelpIconOverviewBarProps {
  title: string
  icon: React.ReactNode
  tooltip?: string
  rightComponent: React.ReactNode
  rowStyle: object
}

const ClickableCardContainer = styled.div`
  cursor: pointer;
`

export const HelpIconOverviewBar: React.FunctionComponent<HelpIconOverviewBarProps> = ({
  title,
  icon,
  tooltip,
  rightComponent,
  rowStyle,
}) => {
  const [openTooltip, setOpenTooltip] = React.useState(false)

  const handleClickToolTip = (e: React.MouseEvent) => {
    e.stopPropagation()
    setOpenTooltip(!openTooltip)
  }

  return (
    <>
      <Row align="center" style={rowStyle}>
        <Col>
          <Row align="center">
            <IconContainer onClickHelp={tooltip ? handleClickToolTip : undefined}>{icon}</IconContainer>
            <Spacer spacing={1} />
            <H2 color={theme.colors.greyDark}>{title}</H2>
          </Row>
        </Col>
        {rightComponent}
      </Row>
      {tooltip && <TooltipRow visible={openTooltip} tooltip={tooltip} />}
    </>
  )
}

const SettingsCardOverviewBar: React.FunctionComponent<SettingsOverviewBarProps> = ({
  title,
  open,
  onClick,
  icon,
  tooltip,
}) => {
  return (
    <ClickableCardContainer onClick={onClick}>
      <CardBar open={open}>
        <HelpIconOverviewBar
          title={title}
          icon={icon}
          tooltip={tooltip}
          rightComponent={<ArrowDropDownIcon open={open} />}
          rowStyle={{ padding: "0 15px", justifyContent: "space-between" }}
        />
      </CardBar>
    </ClickableCardContainer>
  )
}

export default SettingsCardOverviewBar
