import * as React from "react"
import styled from "styled-components"
import BodyText from "../../atoms/typography/BodyText"
import { Spacer } from "../../atoms/spacers"
import H3 from "../../atoms/typography/H3"
import { ArrowDropDownIcon } from "../../atoms/icons/ArrowDropDownIcon"
import { SlideOutHeight } from "../../atoms/animations/SlideOutHeight"
import { capitalizeFirstLetter } from "../../../utils/formatters"
import { SystemMessage } from "../../../edge/SystemMessages"
import { theme } from "../../theme"
import SettingsField from "../../atoms/card/SettingsField"
import dayjs from "dayjs"

const MONTH_CHARACTER_COUNT = 3

const ErrorMessageFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
`

const SystemMessageInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 8px;
  gap: 8px;
  align-self: stretch;
`

const SystemMessageTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

function toColor(msg: SystemMessage): string {
  const resolveState = msg.resolveState
  if (resolveState.type === "resolved") {
    return theme.colors.greyDisabled
  }
  switch (msg.severity) {
    case "info": {
      return theme.colors.greyDarker
    }
    case "warning": {
      return theme.colors.orange
    }
    case "error": {
      return theme.colors.redDark
    }
  }
}

function fmtTime(time: dayjs.Dayjs): string {
  return time.format("HH:mm:ss DD ") + time.format("MMMM").substring(0, MONTH_CHARACTER_COUNT) + time.format(" YYYY")
}

const SystemMessageField: React.FunctionComponent<SystemMessage> = (msg: SystemMessage) => {
  const [expanded, setExpanded] = React.useState(false)
  const color = toColor(msg)

  return (
    <SettingsField>
      <ErrorMessageFieldContainer
        onClick={() => {
          setExpanded(!expanded)
        }}
      >
        <SystemMessageTitleContainer>
          <>
            <BodyText monospace color={color}>
              {fmtTime(msg.time)}
            </BodyText>
            <Spacer spacing={1} />
          </>
          <H3 color={color}>{`${msg.resolveState.type === "resolved" ? "Resolved: " : ""}${msg.title}`}</H3>
        </SystemMessageTitleContainer>
        <ArrowDropDownIcon open={expanded} />
      </ErrorMessageFieldContainer>
      <SlideOutHeight isVisible={expanded}>
        <SystemMessageInnerContainer>
          <H3 color={color}>{msg.text}</H3>
          <BodyText color={color}>Type: {capitalizeFirstLetter(msg.severity)}</BodyText>
          {msg.resolveState.type === "resolved" && (
            <H3 monospace color={color}>
              Resolved {fmtTime(msg.resolveState.time)}
            </H3>
          )}
        </SystemMessageInnerContainer>
      </SlideOutHeight>
    </SettingsField>
  )
}

export default SystemMessageField
