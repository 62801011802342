import * as React from "react"
import { Col, Row } from "react-grid-system"
import { HorizontalSpacer } from "../atoms/spacers"
import ContactInformationCard from "../organisms/support/ContactInformationCard"
import GuidesAndManualsCard from "../organisms/support/GuidesAndManualsCard"
import PageContainer from "../atoms/PageContainer"

export const ERROR_CODE_INDEX_TOOLTIP = `
This section contains a list of known errors, along with descriptions of the causes and fixes. Use it to troubleshoot, or when in contact with support in case of problems with your terminal.
`

const GUIDES_AND_MANUALS_FILES = [
  {
    fileName: "Satcube Motion quick start guide",
    url: "/guides/motion_quick_start_guide.pdf",
  },
]

const SupportPage: React.FunctionComponent = () => {
  return (
    <PageContainer>
      <HorizontalSpacer spacing={4} />
      <Row>
        <Col>
          <div id="contact">
            <ContactInformationCard />
          </div>
          <HorizontalSpacer spacing={4} />
          <div id="guides-and-manuals">
            <GuidesAndManualsCard files={GUIDES_AND_MANUALS_FILES} />
          </div>
          {/* TODO unhide after mvp */}
          {/* <HorizontalSpacer spacing={4} />
          <div id="error-codes">
            <ErrorCodeIndexCard errors={ERROR_CODE_INDEX_ERRORS} />
          </div> */}
          <HorizontalSpacer spacing={4} />
        </Col>
      </Row>
    </PageContainer>
  )
}

export default SupportPage
