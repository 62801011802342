import * as React from "react"
import SettingsOverviewCard from "../../molecules/SettingsOverviewCard"
import WrenchIcon from "../../atoms/icons/WrenchIcon"
import ReadOnlyTextField from "../../molecules/card/ReadOnlyTextField"
import EditablePasswordField from "../../molecules/inputs/EditableSettingsSecretField"
import ButtonRow from "../../molecules/card/ButtonRow"
import TextButton from "../../atoms/buttons/TextButton"
import ResetIcon from "../../atoms/icons/ResetIcon"
import { theme } from "../../theme"
import FactoryResetModal from "../modals/FactoryResetModal"
import { SECTION_SYSTEM_GENERAL } from "../../../utils/pages"
import { useEdgeApi } from "../../../edge/EdgeProvider"
import FactoryResetProgressModal from "../modals/FactoryResetProgressModal"
import TextEditor from "../../molecules/inputs/TextEditor"

const GeneralCard: React.FunctionComponent = () => {
  const { config, metrics, metadata, factoryReset, factoryResetState } = useEdgeApi()

  const systemTime = metrics.useSystemTimeTimestamp()
  const time = systemTime?.format("HH:mm:ss (Z)")
  const date = systemTime?.format("YYYY-MM-DD")

  // TODO: Hard coded for now, since we only have one user and there is
  // currently no way to get the username.
  const userName = "admin"

  const [factoryResetModalOpen, setFactoryResetModalOpen] = React.useState(false)

  const handleConfirmFactoryReset = () => {
    factoryReset()
    setFactoryResetModalOpen(false)
  }

  return (
    <>
      <SettingsOverviewCard title={SECTION_SYSTEM_GENERAL.name} icon={<WrenchIcon />}>
        <ReadOnlyTextField label="Terminal Serial Number" value={metadata?.serialNumber} placeholder="------" />
        <ReadOnlyTextField label="Time" value={time} placeholder="-- : --" />
        <ReadOnlyTextField label="Date" value={date} placeholder="---- / -- / --" />
        <ReadOnlyTextField label="User" value={userName} placeholder="-----" />
        <TextEditor label="Password" isSecret={true} editor={config.useAdminPassword("General")} />
        <ButtonRow
          label="Factory reset"
          button={
            <TextButton
              onClick={() => {
                setFactoryResetModalOpen(true)
              }}
              title="Revert to factory settings"
              color={theme.colors.redDark}
              icon={<ResetIcon />}
            />
          }
        />
      </SettingsOverviewCard>
      {factoryResetModalOpen && (
        <FactoryResetModal
          open={factoryResetModalOpen}
          onClose={() => setFactoryResetModalOpen(false)}
          onConfirm={handleConfirmFactoryReset}
        />
      )}
      {!factoryResetModalOpen && factoryResetState !== undefined && (
        <FactoryResetProgressModal factoryResetState={factoryResetState} />
      )}
    </>
  )
}

export default GeneralCard
